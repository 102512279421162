new WOW({ offset: 100 }).init();

$(document).ready(function () {

	$("img.has-tooltip").tooltip({
		title: function () {
			return $(this).attr("alt");
		},
		placement: "auto"
	});
	$("a.has-tooltip").tooltip({
		placement: "auto"
	});

	$("#Searchbox img").addClass("wow fadeIn");


	$(".SearchPanel a").html("<img src='https://bouncycastlenetwork-res.cloudinary.com/image/upload/v1564412599/themes/030/search-arrow.png' alt='search'>");

	function fixHeights() {
		$("#testimonialOuter .RotatorTestimonial").sameHeight();
	}

	setTimeout(fixHeights, 500);

	$(window).on("resize", function () {
		setTimeout(fixHeights, 500);
	});


	$("#HomepageGallery .Previous").click(function (e) {
		var img = $("#HomepageGalleryInner div").first();
		img.animate({ width: 0 }, 1000, "linear", function () {
			img.remove();
			$("#HomepageGalleryInner").append(img);
			img.width(170);
		});
		e.preventDefault();
		return false;
	});

	$("#HomepageGallery .Next").click(function (e) {
		var img = $("#HomepageGalleryInner div").last();
		img.width(0);
		img.remove();
		$("#HomepageGalleryInner").prepend(img);
		img.animate({ width: 170 }, 1000, "linear");
		e.preventDefault();
		return false;
	});

});

